import React from "react";
import './styles.css';

function AboutCarousel() {
    return (
        <div style={{
            height: '100vh',
            backgroundImage: 'url("/photos/mshiko.jpg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
        </div>
    );
}
export default AboutCarousel;